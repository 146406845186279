import {
  Callout,
  DirectionalHint,
  getTheme,
  IconButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { Route, RouteStation } from '@microsoft-life/shanghai-connector-data-access';
import { ShiftDirection } from '@microsoft-life/shanghai-connector-data-access/src/model';
import { useState } from 'react';
import { BaiduMap, MapTypeControl, Marker, NavigationControl } from 'react-baidu-maps';
import { useNavigate, useParams } from 'react-router-dom';
import { PageRoutes } from '.';
import { RouterLink } from '../components/link';
import { useRouteData } from '../data';
import { useResponsiveInformation } from '../helpers/responsive';
import { useLocalizedLabels } from '../localization';

export function RoutePage({ routes }: { routes: Route[] }) {
  const { routeId } = useParams<{ routeId: string }>(),
    localizedLabels = useLocalizedLabels();
  const route = routes.filter(r => r.routeId === routeId)[0];
  return route ? (
    <RoutePageCore routes={routes} route={route} />
  ) : (
    <Text>{localizedLabels.routePage.routeNotFound}</Text>
  );
}

function RoutePageCore({ routes, route }: { routes: Route[]; route: Route }) {
  const theme = getTheme(),
    responsive = useResponsiveInformation(),
    localizedLabels = useLocalizedLabels();
  const chunkSize = responsive.isBigScreen
    ? 4
    : responsive.isDesktopOrLaptop
    ? 3
    : responsive.isTablet
    ? 2
    : 1;
  const stations = useRouteData(route.routeId);
  const chunks = Math.ceil(stations.length / chunkSize);
  return (
    <>
      <div style={{ marginBottom: theme.spacing.s1 }}>
        <Text variant="xLarge" block>
          {localizedLabels.common.getName(route.name)}
        </Text>
        <Text style={{ color: theme.palette.neutralSecondary }}>
          {localizedLabels.routeCard.vehiclesInService(route)}
        </Text>
      </div>
      {stations.length ? (
        <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
          {[...Array(chunks).keys()].map(chunk => (
            <Stack.Item>
              <Stack horizontal tokens={{ childrenGap: theme.spacing.s2 }}>
                {stations.slice(chunk * chunkSize, (chunk + 1) * chunkSize).map(station => (
                  <Stack.Item
                    key={station.stationId}
                    styles={{ root: { width: `${100 / chunkSize}%` } }}
                  >
                    <StationCard routes={routes} route={route} station={station} />
                  </Stack.Item>
                ))}
              </Stack>
            </Stack.Item>
          ))}
        </Stack>
      ) : (
        <>
          <Spinner
            label={localizedLabels.common.loading}
            labelPosition="right"
            size={SpinnerSize.medium}
          />
        </>
      )}
    </>
  );
}

function StationCard({
  routes,
  route,
  station
}: {
  routes: Route[];
  route: Route;
  station: RouteStation;
}) {
  const theme = getTheme(),
    navigate = useNavigate(),
    responsive = useResponsiveInformation(),
    localizedLabels = useLocalizedLabels(),
    calloutTriggerId = useId(),
    [calloutVisible, setCalloutVisible] = useState(false);
  const mapWidth = responsive.isDesktopOrLaptop ? '50vh' : responsive.isTablet ? '80vh' : '100vh';
  const otherRoutes = station.routes.flatMap(r =>
      routes.filter(rr => rr.routeId === r.routeId && rr.routeId !== route.routeId)
    ),
    truncateOtherRoutesAt = 2;

  return (
    <div style={{ boxShadow: theme.effects.elevation8, height: '100%' }}>
      <div style={{ padding: theme.spacing.m }}>
        <Text
          block
          variant="mediumPlus"
          id={calloutTriggerId}
          onClick={() => setCalloutVisible(true)}
        >
          {localizedLabels.common.getName(station.name)}
        </Text>
        <div style={{ marginBottom: theme.spacing.s2 }}>
          <Text block>{localizedLabels.stationCard.timeOfStop}</Text>
          <Text block style={{ color: theme.palette.neutralSecondary }}>
            {localizedLabels.stationCard.goingToOffice}:{' '}
            {station.stops
              .filter(s => s.direction === ShiftDirection.HeadingToOffice)
              .map(s => s.time + ' ')}
          </Text>
          <Text block style={{ color: theme.palette.neutralSecondary }}>
            {localizedLabels.stationCard.goingHome}:{' '}
            {station.stops
              .filter(s => s.direction === ShiftDirection.HeadingHome)
              .map(s => s.time + ' ')}
          </Text>
        </div>
        {otherRoutes.length > 0 && (
          <div style={{ marginBottom: theme.spacing.s2 }}>
            <Text block>{localizedLabels.stationCard.otherLines}</Text>
            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: theme.spacing.s2 }}>
              {otherRoutes.slice(0, truncateOtherRoutesAt).map(r => (
                <RouterLink
                  key={r.routeId}
                  href={PageRoutes.routeDetails(r.routeId)}
                  style={{ color: theme.palette.neutralSecondary }}
                >
                  {localizedLabels.common.getName(r.name)}
                </RouterLink>
              ))}
              {otherRoutes.length > truncateOtherRoutesAt && (
                <IconButton
                  title="More"
                  menuIconProps={{ iconName: 'More' }}
                  styles={{
                    root: { height: theme.fonts.medium.fontSize }
                  }}
                  menuProps={{
                    items: otherRoutes.slice(truncateOtherRoutesAt).map(r => ({
                      key: r.routeId,
                      name: localizedLabels.common.getName(r.name),
                      onClick: () => navigate(PageRoutes.routeDetails(r.routeId))
                    }))
                  }}
                />
              )}
            </Stack>
          </div>
        )}

        {calloutVisible && (
          <Callout
            aria-label="Location"
            role="dialog"
            gapSpace={0}
            target={`#${calloutTriggerId}`}
            onDismiss={() => setCalloutVisible(false)}
            beakWidth={8}
            directionalHint={DirectionalHint.bottomLeftEdge}
            styles={{ root: { padding: theme.spacing.m } }}
          >
            <BaiduMap
              mapContainer={
                <div
                  style={{
                    height: mapWidth,
                    width: mapWidth,
                    maxHeight: '80vh',
                    maxWidth: '80vw'
                  }}
                />
              }
              defaultCenter={{ lng: station.location.longitude, lat: station.location.latitude }}
              defaultZoom={13}
            >
              <NavigationControl type="small" />
              <MapTypeControl />
              <Marker
                position={{ lng: station.location.longitude, lat: station.location.latitude }}
              />
            </BaiduMap>
          </Callout>
        )}
      </div>
    </div>
  );
}
