import { getTheme, Link, Stack, Text, Toggle } from '@fluentui/react';
import { useLocalizationPreference } from '../helpers/localization';
import { useLocalizedLabels } from '../localization';

export function Footer() {
  const theme = getTheme();
  const [useChinese, setUseChinese] = useLocalizationPreference();
  const localizedLabels = useLocalizedLabels();
  return (
    <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
      <Stack.Item grow>
        <Text style={{ color: theme.palette.neutralSecondary }}>
          &copy; -{' '}
          <Link href="https://github.com/yifanbian/mssh-connector" rel="noreferrer noopener">
            GitHub
          </Link>
        </Text>
      </Stack.Item>
      <Stack.Item>
        <Toggle
          inlineLabel
          label={localizedLabels.footer.languageSwitchLabel}
          checked={useChinese}
          onChange={(_, checked) => setUseChinese(checked ?? false)}
        />
      </Stack.Item>
    </Stack>
  );
}
