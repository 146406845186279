import { PassingRoute, Route, RouteStation, RouteStationSchedule, ShiftDirection } from './model';

export type GetRouteResponse = {
  Id: string; // Route ID
  IncludedShuttles: string; // Number of vehicles
  Name_1stLang: string;
  Name_2ndLang: string;
};

export function convertGetRouteResponse(route: GetRouteResponse): Route {
  return {
    routeId: route.Id,
    name: {
      chinese: route.Name_1stLang,
      english: route.Name_2ndLang
    },
    numberOfVehicles: parseInt(route.IncludedShuttles)
  };
}

export type GetRoutesResponse = {
  value: GetRouteResponse[];
};

export function convertGetRoutesResponse(routes: GetRoutesResponse): Route[] {
  return routes.value.map(convertGetRouteResponse);
}

export type GetStationResponse = {
  StationId: string,
  LocationLatitude: string,
  LocationLongitude: string,
  Name_1stLang: string
  Name_2ndLang: string,
  Position: number,
  RouteSchedules: {
    Id: string,
    RoundNum: number,
    Time: string,
    HeadingOut: boolean, // Heading to office or home
  }[],
  RouteToStations: {
    GpsTracking: boolean,
    Id: string,
    RouteId: string,
    StationId: string,
  }[],
};

export function convertGetStationResponse(station: GetStationResponse): RouteStation {
  return {
    stationId: station.StationId,
    name: {
      chinese: station.Name_1stLang,
      english: station.Name_2ndLang,
    },
    location: {
      latitude: parseFloat(station.LocationLatitude),
      longitude: parseFloat(station.LocationLongitude),
    },
    position: station.Position,
    stops: station.RouteSchedules.map(schedule => ({
      stopId: schedule.Id,
      shiftNumber: schedule.RoundNum,
      time: schedule.Time,
      direction: schedule.HeadingOut ? ShiftDirection.HeadingToOffice : ShiftDirection.HeadingHome
    } as RouteStationSchedule)).sort((a, b) => a.shiftNumber - b.shiftNumber),
    routes: station.RouteToStations.map(route => ({
      routeId: route.RouteId,
      stationId: route.StationId,
    } as PassingRoute)),
  };
}

export type GetRouteScheduleResponse = {
  value: GetStationResponse[],
};

export function convertGetRouteScheduleResponse(routeSchedule: GetRouteScheduleResponse): RouteStation[] {
  return routeSchedule.value.map(convertGetStationResponse).sort((a, b) => a.position - b.position);
}
