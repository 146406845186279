import { getTheme, IconButton, ILinkStyles, Stack, Text } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import { useResponsiveInformation } from '../helpers/responsive';
import { useLocalizedLabels } from '../localization';
import { PageRoutes } from '../pages';
import { RouterLink } from './link';

export function NavBar() {
  const theme = getTheme(),
    navigate = useNavigate(),
    localizedLabels = useLocalizedLabels(),
    responsive = useResponsiveInformation();
  const verticalCenter = {
    marginTop: 'auto',
    marginBottom: 'auto'
  };
  const navbarLinkStyles: ILinkStyles = {
    root: { color: theme.palette.neutralDark, ...verticalCenter }
  };
  return (
    <Stack
      horizontal
      verticalAlign="center"
      tokens={{ childrenGap: 16 }}
      styles={{ root: { height: '32px' } }}
    >
      <Stack.Item grow>
        <Text variant="xLarge" nowrap block>
          Shanghai Zizhu Connectors
        </Text>
      </Stack.Item>
      {responsive.isMobile ? (
        <Stack.Item>
          <IconButton
            ariaLabel="Menu"
            menuIconProps={{ iconName: 'CollapseMenu' }}
            menuProps={{
              items: [
                {
                  key: PageRoutes.routes,
                  text: localizedLabels.nav.routesLink,
                  onClick: () => navigate(PageRoutes.routes)
                },
                {
                  key: PageRoutes.stations,
                  text: localizedLabels.nav.stationsLink,
                  onClick: () => navigate(PageRoutes.stations)
                }
              ]
            }}
          />
        </Stack.Item>
      ) : (
        <>
          <Stack.Item>
            <RouterLink href={PageRoutes.routes} styles={navbarLinkStyles}>
              {localizedLabels.nav.routesLink}
            </RouterLink>
          </Stack.Item>
          <Stack.Item>
            <RouterLink href={PageRoutes.stations} styles={navbarLinkStyles}>
              {localizedLabels.nav.stationsLink}
            </RouterLink>
          </Stack.Item>
        </>
      )}
    </Stack>
  );
}
