import axios from 'axios';
import { convertGetRouteScheduleResponse, convertGetRoutesResponse, GetRouteScheduleResponse, GetRoutesResponse } from './api-contract';
import SiteConfig from './config';
import { Route, RouteStation } from './model';

const request = axios.create({
  baseURL: SiteConfig.apiBase,
  timeout: 2000,
});

export function getRoutes(): Promise<Route[]> {
  return request.get<GetRoutesResponse>(`GetRoutesbySiteId2(SiteId=${encodeURIComponent(SiteConfig.siteId)})`)
    .then(response => convertGetRoutesResponse(response.data));
}

export function getRouteSchedule(routeId: string): Promise<RouteStation[]> {
  return request.get<GetRouteScheduleResponse>(`GetSchedulebyRouteId(RouteId=${encodeURIComponent(routeId)})`)
    .then(response => convertGetRouteScheduleResponse(response.data));
}

export function getAllStations(): Promise<RouteStation[]> {
  return request.get<GetRouteScheduleResponse>(`GetStationsBySite2(SiteId=${encodeURIComponent(SiteConfig.siteId)})`)
    .then(response => convertGetRouteScheduleResponse(response.data));
}

export { Route, RouteStation };
