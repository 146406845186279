import { useMediaQuery } from 'react-responsive';

export function useResponsiveInformation() {
  return {
    isBigScreen: useMediaQuery({ minWidth: 1824 }),
    isDesktopOrLaptop: useMediaQuery({ minWidth: 1224 }),
    isTablet: useMediaQuery({ maxWidth: 1224, minWidth: 768 }),
    isMobile: useMediaQuery({ maxWidth: 767 })
  };
}
