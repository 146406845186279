import {
  getRoutes,
  getRouteSchedule,
  Route,
  RouteStation
} from '@microsoft-life/shanghai-connector-data-access';
import { useEffect, useState } from 'react';

export function useConnectorData() {
  const [routes, setRoutes] = useState<Route[]>([]);
  useEffect(() => {
    getRoutes().then(setRoutes);
  }, []);
  return {
    routes
  };
}

export function useRouteData(routeId: string) {
  const [routeSchedules, setRouteSchedules] = useState<RouteStation[]>([]);
  useEffect(() => {
    getRouteSchedule(routeId).then(setRouteSchedules);
  }, []);
  return routeSchedules;
}
