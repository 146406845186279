import { useState } from 'react';

export function getLocalStorage<T>(key: string, initialValueFactory: () => T): T {
  if (typeof window === 'undefined') return initialValueFactory();
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValueFactory();
  } catch (error) {
    console.log(error);
    return initialValueFactory();
  }
}

export function useLocalStorage<T>(key: string, initialValueFactory: () => T): [T, (_: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() =>
    getLocalStorage(key, initialValueFactory)
  );
  const setValue = (value: T) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
}
