import { Route } from '@microsoft-life/shanghai-connector-data-access';
import { getLocalizationPreference } from '../helpers/localization';

export function useLocalizedLabels() {
  const useChinese = getLocalizationPreference();
  return {
    common: {
      loading: useChinese ? '加载中……' : 'Loading...',
      getName: (names: { chinese: string; english: string }) =>
        useChinese ? names.chinese : names.english
    },
    nav: {
      routesLink: useChinese ? '路线' : 'Routes',
      stationsLink: useChinese ? '站点' : 'Stations'
    },
    routeCard: {
      vehiclesInService: (route: Route) =>
        useChinese
          ? `由 ${route.numberOfVehicles} 辆车提供服务`
          : `${route.numberOfVehicles} vehicles in service`
    },
    footer: {
      languageSwitchLabel: useChinese ? '中文' : 'English'
    },
    routePage: {
      routeNotFound: useChinese ? '该线路不存在' : "We can't find this route."
    },
    stationCard: {
      timeOfStop: useChinese ? '停靠时间' : 'Time of stops',
      goingToOffice: useChinese ? '上班方向' : 'Heading to office',
      goingHome: useChinese ? '下班方向' : 'Heading home',
      otherLines: useChinese ? '其他途经本站的线路' : 'Other lines at this station'
    }
  };
}
