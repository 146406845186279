export type Route = {
  routeId: string,
  name: {
    chinese: string,
    english: string,
  },
  numberOfVehicles: number,
};

export enum ShiftDirection { HeadingToOffice, HeadingHome };

export type RouteStationSchedule = {
  stopId: string,
  shiftNumber: number,
  time: string,
  direction: ShiftDirection,
}

export type PassingRoute = {
  routeId: string,
  stationId: string,
};

export type RouteStation = {
  stationId: string,
  name: {
    chinese: string,
    english: string,
  },
  location: {
    latitude: number,
    longitude: number,
  },
  position: number,
  stops: RouteStationSchedule[],
  routes: PassingRoute[],
};
