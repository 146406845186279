import { getTheme, Stack, Text } from '@fluentui/react';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Footer } from './components/footer';
import { NavBar } from './components/navbar';
import { useConnectorData } from './data';
import { PageRoutes } from './pages';
import { RoutePage } from './pages/route';
import { RoutesPage } from './pages/routes';

export function App() {
  const theme = getTheme();
  const { routes } = useConnectorData();
  const padding = {
    paddingLeft: theme.spacing.l1,
    paddingRight: theme.spacing.l1,
    paddingTop: theme.spacing.s1,
    paddingBottom: theme.spacing.s1
  };
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Stack tokens={{ childrenGap: theme.spacing.m }}>
          <Stack.Item style={{ boxShadow: theme.effects.elevation16, ...padding }}>
            <NavBar />
          </Stack.Item>

          <Stack.Item grow style={padding}>
            <Routes>
              <Route
                path={PageRoutes.home}
                element={<Text>Select a page in the navigation bar to begin.</Text>}
              />
              <Route path={PageRoutes.routes} element={<RoutesPage routes={routes} />} />
              <Route path={PageRoutes.routeDetails()} element={<RoutePage routes={routes} />} />
              <Route path={PageRoutes.stations} element={<Text>All stations</Text>} />
            </Routes>
          </Stack.Item>

          <Stack.Item style={padding}>
            <Footer />
          </Stack.Item>
        </Stack>
      </BrowserRouter>
    </React.StrictMode>
  );
}
