import { getLocalStorage, useLocalStorage } from './local-storage';

export const useLocalizationPreference = () =>
  useLocalStorage('useChinese', browserDefaultToChinese);

export const getLocalizationPreference = () =>
  getLocalStorage('useChinese', browserDefaultToChinese);

const browserDefaultToChinese = () =>
  typeof navigator !== 'undefined' ? navigator.language.startsWith('zh-') : false;
