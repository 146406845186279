import { getTheme, Stack, Text } from '@fluentui/react';
import { Route } from '@microsoft-life/shanghai-connector-data-access';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '.';
import { useResponsiveInformation } from '../helpers/responsive';
import { useLocalizedLabels } from '../localization';

function RouteCard({ route }: { route: Route }) {
  const theme = getTheme(),
    navigate = useNavigate(),
    localizedLabels = useLocalizedLabels();
  return (
    <div style={{ boxShadow: theme.effects.elevation8, padding: theme.spacing.m }}>
      <Text
        variant="mediumPlus"
        block
        onClick={() => navigate(PageRoutes.routeDetails(route.routeId))}
      >
        {localizedLabels.common.getName(route.name)}
      </Text>
      <Text>{localizedLabels.routeCard.vehiclesInService(route)}</Text>
    </div>
  );
}

export function RoutesPage({ routes }: { routes: Route[] }) {
  const theme = getTheme(),
    responsive = useResponsiveInformation();
  const chunkSize = responsive.isBigScreen
    ? 4
    : responsive.isDesktopOrLaptop
    ? 3
    : responsive.isTablet
    ? 2
    : 1;
  const chunks = Math.ceil(routes.length / chunkSize);
  return (
    <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
      {[...Array(chunks).keys()].map(chunk => (
        <Stack.Item>
          <Stack horizontal tokens={{ childrenGap: theme.spacing.s2 }}>
            {routes.slice(chunk * chunkSize, (chunk + 1) * chunkSize).map(route => (
              <Stack.Item key={route.routeId} styles={{ root: { width: `${100 / chunkSize}%` } }}>
                <RouteCard route={route} />
              </Stack.Item>
            ))}
          </Stack>
        </Stack.Item>
      ))}
    </Stack>
  );
}
